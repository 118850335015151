import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ stats }) => {
  if (!stats || stats.isHide) return null

  return (
    <div className={styles.stats}>
      <div className={styles.wrap}>
        {stats.items && stats.items.map(item => (
          <div className={styles.item} key={item._key}>
            <p className={styles.title}>{item.title}</p>
            {item.rating?.url ? (
              <Link to={item.rating.url} className={styles.rating}>
                {item.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(item.rating.starsImage)} alt={item.rating?.text}/>}
                {item.rating.text && <span className={styles.ratingText}>{item.rating.text}</span>}
              </Link>
            ) : (
              <p className={styles.description}>{item.description}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
import React, { useEffect } from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const scrollToTestosteroneProtocols = () => {
    gsap.to(window, {
      duration: 1.5,
      scrollTo: document.getElementById('testosterone-protocols'),
    })
  }

  const scrollToOtherProtocols = () => {
    gsap.to(window, {
      duration: 1.5,
      scrollTo: document.getElementById('other-protocols'),
    })
  }

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
          <p className={styles.titleMobile} dangerouslySetInnerHTML={{ __html: header.titleMobile }} />
          <p className={styles.description}>{header.description}</p>
          <div className={styles.btns}>
            <a onClick={scrollToTestosteroneProtocols} className={styles.btnsBlue}>{header.buttonText1}</a>
            <a onClick={scrollToOtherProtocols} className={styles.btnsDefault}>{header.buttonText2}</a>
          </div>         
        </div>
      </div>
      <img className={styles.image} {...srcSetProps(sanityImageUrl(header.image))} alt={header.image?.caption} />
      <img className={styles.imageMobile} {...srcSetProps(sanityImageUrl(header.imageMobile))} alt={header.imageMobile?.caption} />
    </div>
  )
}

import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import SwiperObj, { Pagination, FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './style.module.sass'

export default ({ protocols }) => {
  if (!protocols || protocols.isHide) return null

  SwiperObj.use([Pagination, FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 96,
    slidesPerView: 3,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    pagination: {
      el: `.${styles.dots}`,
      clickable: true
    },
    className: styles.slider,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 16
      },
      500: {
        slidesPerView: 2.2,
        spaceBetween: 16
      },
      800: {
        slidesPerView: 3.2,
        spaceBetween: 16
      },
      1100: {
        slidesPerView: 3,
        spaceBetween: 96
      }
    },
  }

  return (
    <div className={styles.protocols}>
      <div className={styles.anchor} id="testosterone-protocols" />
      <div className={styles.wrap}>
        <h2 className={styles.title}>{protocols.title}</h2>
        {protocols.rating?.url && (
          <Link to={protocols.rating.url} className={styles.rating}>
            {protocols.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(protocols.rating.starsImage)} alt={protocols.rating?.text}/>}
            {protocols.rating.text && <span className={styles.ratingText}>{protocols.rating.text}</span>}
          </Link>
        )}
        <Swiper {...sliderSettings}>
          {protocols.list &&
            protocols.list.map(protocol => (
              <SwiperSlide className={styles.item} key={protocol._key}>
                <div className={styles.top}>
                  {protocols.bestSeller?.name === protocol.name && <div className={styles.tag}>Best Seller</div>}
                  {protocols.mostPowerful?.name === protocol.name && <div className={styles.tag}>Most Powerful</div>}
                  <div className={styles.image}>
                    <img src={sanityImageUrl(protocol.imageSquare)} className={styles.imageSrc} alt={protocol.imageSquare?.caption} />
                  </div>
                  <p className={styles.name}>{protocol.name}</p>
                  <div className={styles.ingredients}>
                    {protocol.ingredients && protocol.ingredients.map(item => (
                      <p className={styles.ingredientsItem} key={item._key}>
                        {item.name}
                      </p>
                    ))}
                  </div>                  
                  <p className={styles.info}>{protocol.descriptionShort}</p>
                </div>
                <div className={styles.bottom}>
                  <p className={styles.price}>Starting at <span>{protocol.price}</span>/mo</p>
                  {protocol.learnMoreButtonText && protocol.learnMoreButtonUrl && (
                    <Link to={protocol.learnMoreButtonUrl} className={styles.link}>
                      {protocol.learnMoreButtonText}
                    </Link>
                  )}
                </div>
              </SwiperSlide>
            ))}
          <div className={styles.dots} />
        </Swiper>
      </div>
    </div>
  )
}
